<template>
	<transition name="modal">
		<div class="modal__mask" @click="hideModal">
				<div class="modal__wrapper">
					<div class="modal__container" @click.stop>
						<header class="modal__header">
							<h4 class="" v-if="header">{{header}}</h4>
							<a @click="hideModal()" class="close icon" style="vertical-align: top;">close</a>
						</header>
						<article class="modal__content">
							<slot></slot>
						</article>
					</div>
				</div>
		</div>
	</transition>
</template>

<script>
// Component
export default {
	name: 'modal',
	props: {
		content: {
			type: String,
		},
		header: {
			type: String,
		},
	},
	data: () => ({
	}),
	mounted() {
		document.addEventListener('keydown', this.close)
	},
	destroyed() {
		document.removeEventListener('keydown', this.close)
	},
	methods: {
		hideModal() {
			this.$emit('close')
		},
		close(evt) {
			if (evt.key) {
				if (evt.key === 'Escape' || evt.key === 'Esc') {
					this.$emit('close')
				}
			} else if (evt.keyCode === 27) {
				this.$emit('close')
				this.$emit('close')
			}
		},
	},
}
</script>

<style scoped lang="scss">
.modal {
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;

	&__mask {
		animation-fill-mode: forwards;
		background-color: rgba(color_('gray','xdark'),0.5);
		height: 100%;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 200;
	}

	&__wrapper {
		align-items: center;
		animation-fill-mode: forwards;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		height: 100%;
		width: 100%;
		will-change: transform;
	}

	&__container {
		background-color: rgb(255,255,255);
		border-radius: 5px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		min-height: 450px;
		max-height: 90%;
		max-width: 900px;
		width: 100%;

		@include media-breakpoint-down(md) {
			min-height: 0;
			top: 100px;
			width: 90%;
			left: 5%;
		}
	}

	&__header {
		align-items: center;
		border-bottom: 1px solid color_('gray','light');
		display: flex;
		font-size: 2.4rem;
		font-weight: 500;
		line-height: 1.2;
		justify-content: center;
		// padding: 15px;
		min-height: 60px;
		position: relative;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 15px;

		h4 {
			font-family: $font-family-secondary;
			font-size: 30px;
			color: #000000;
			// line-height: 45px;
			margin: $margin;
		}

		.close {
			color: color_('white') !important;
			font-size: 30px;
			opacity: 1;
			position: absolute;
			right: 10px;
			top: -40px;
		}
	}

	&__content {
		// flex: 1 0 auto;
		overflow-y: auto;
		padding: 2rem;
		padding-top: 0;
		-webkit-overflow-scrolling: touch;
	}

	&-enter-active {
		animation: modalIn 500ms $easing-material;
		will-change: auto;

		.modal__wrapper {
			animation: scaleIn 333ms $easing-standard;
			will-change: auto;
		}
	}

	&-leave-active {
		animation: modalOut 350ms $easing-material;
		will-change: auto;

		.modal__wrapper {
			animation: scaleOut 350ms $easing-standard;
			will-change: auto;
		}
	}
}

// Animations
@keyframes modalIn {
	0% {
		background-color: rgba(color_('gray','xdark'),0);
	}

	100% {
		background-color: rgba(color_('gray','xdark'),0.5);
	}
}

@keyframes modalOut {
	0% {
		background-color: rgba(color_('gray','xdark'),0.5);
	}

	100% {
		background-color: rgba(color_('gray','xdark'),0);
	}
}

@keyframes scaleIn {
	0% {
		transform: translate3d(0,100vh,0) scale(0);
		visibility: visible;
	}

	100% {
		transform: translate3d(0,0,0) scale(1);
	}
}

@keyframes scaleOut {
	0% {
		transform: translate3d(0,0,0) scale(1);
		visibility: visible;
	}

	100% {
		transform: translate3d(0,100vh,0) scale(0);
		visibility: hidden;
	}
}
</style>
