<template>
	<div class="main">
		<router-view name="header" class="main__top"></router-view>
		<div class="main__middle container">
			<div class="main__content col-12">
				<router-view name="error" class="main__content-body" v-if="errorMessage"></router-view>
				<router-view name="content" class="main__content-body" v-if="!errorMessage"></router-view>
			</div>
		</div>

		<router-view name="footer" class="main__bottom"></router-view>

		<modal
			@close="hideModal()"
			v-if="modalVisible"
			:header="modalTitle"
		>
			<router-view name="modal"></router-view>
		</modal>
	</div>
</template>

<script>
// Dependencies
import Modal from '@/components/Modal.vue'

// Component
export default {
	name: 'viewMain',
	components: {
		Modal,
	},
	props: {
		errorMessage: {
			type: [String, Object, Error],
		},
	},
	data: () => ({
		modalVisible: false,
		modalTitle: '',
	}),
	mounted() {
		this.modalVisible = this.$route.meta.modalVisible
		this.modalTitle = this.$route.meta.modalTitle
	},
	methods: {
		hideModal() {
			this.$router.back()
		},
	},
	watch: {
		'$route'(to) {
			this.modalVisible = to.meta.modalVisible
			this.modalTitle = to.meta.modalTitle
		},
	},
}
</script>

<style scoped lang="scss">
.main {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100%;

	&__middle {
		display: flex;
		flex-direction: row;
		flex: 1 0 auto;
		width: 100%;
	}
}
</style>
